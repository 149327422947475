/*
 * Skin: Easy
 * -----------
 */

.skin-easy .main-header .navbar {
  background-color: #00958C;
}
.skin-easy .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-easy .main-header .navbar .nav > li > a:hover,
.skin-easy .main-header .navbar .nav > li > a:active,
.skin-easy .main-header .navbar .nav > li > a:focus,
.skin-easy .main-header .navbar .nav .open > a,
.skin-easy .main-header .navbar .nav .open > a:hover,
.skin-easy .main-header .navbar .nav .open > a:focus,
.skin-easy .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-easy .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-easy .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-easy .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-easy .main-header .navbar .sidebar-toggle:hover {
  background-color: #06867E;
}
@media (max-width: 767px) {
  .skin-easy .main-header .navbar .dropdown-menu li.divider {
	background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-easy .main-header .navbar .dropdown-menu li a {
	color: #fff;
  }
  .skin-easy .main-header .navbar .dropdown-menu li a:hover {
	background: #06867E;
  }
}
.skin-easy .main-header .logo {
  background-color: #00958C;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-easy .main-header .logo:hover {
  background-color: #06867E;
}
.skin-easy .main-header li.user-header {
  background-color: #00958C;
}
.skin-easy .content-header {
  background: transparent;
}
.skin-easy .wrapper,
.skin-easy .main-sidebar,
.skin-easy .left-side {
  background-color: #f9fafc;
}
.skin-easy .content-wrapper,
.skin-easy .main-footer {
  border-left: 1px solid #d2d6de;
}
.skin-easy .user-panel > .info,
.skin-easy .user-panel > .info > a {
  color: #444444;
}
.skin-easy .sidebar-menu > li {
  -webkit-transition: border-left-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease;
  transition: border-left-color 0.3s ease;
}
.skin-easy .sidebar-menu > li.header {
  color: #848484;
  background: #f9fafc;
}
.skin-easy .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  font-weight: 600;
}
.skin-easy .sidebar-menu > li:hover > a,
.skin-easy .sidebar-menu > li.active > a {
  color: #000000;
  background: #f4f4f5;
}
.skin-easy .sidebar-menu > li.active {
  border-left-color: #00958C;
}
.skin-easy .sidebar-menu > li.active > a {
  font-weight: 600;
}
.skin-easy .sidebar-menu > li > .treeview-menu {
  background: #f4f4f5;
}
.skin-easy .sidebar a {
  color: #444444;
}
.skin-easy .sidebar a:hover {
  text-decoration: none;
  border-left: 4px solid #00958C;
}
.skin-easy .treeview-menu > li > a {
  color: #777777;
}
.skin-easy .treeview-menu > li.active > a,
.skin-easy .treeview-menu > li > a:hover {
  color: #000000;
  border-left: 3px solid #00958C;
}
.treeview.active {
  border-left: 3px solid #00958C;
}
.skin-easy .treeview-menu > li.active > a {
  font-weight: 600;
}
.skin-easy .sidebar-form {
  border-radius: 3px;
  border: 1px solid #d2d6de;
  margin: 10px 10px;
}
.skin-easy .sidebar-form input[type="text"],
.skin-easy .sidebar-form .btn {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid transparent;
  height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.skin-easy .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-easy .sidebar-form input[type="text"]:focus,
.skin-easy .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-easy .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-easy .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
@media (min-width: 768px) {
  .skin-easy.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
	border-left: 1px solid #d2d6de;
  }
}

.box.box-solid.box-easy {
  border: 1px solid #00958C;
}
.box.box-solid.box-easy > .box-header {
  color: #ffffff;
  background: #00958C;
  background-color: #00958C;
}
.box.box-solid.box-easy > .box-header a,
.box.box-solid.box-easy > .box-header .btn {
  color: #ffffff;
}

.box.box-easy {
  border-top-color: #00958C;
  overflow: auto;
}

.bg-easy-active {
 	background-color: #00958C !important;
}

.bg-easy {
  background-color: #00958C !important;
  color:#ffffff;
}

.active-easy {
  background-color: #ffffff !important;
  color: #00958C!important;
  border-color: #ffffff!important;
}

.btn-easy {
  background-color: #00958c;
  color: #ffffff;
}

.buttons-pdf {
  margin-left: 5px;
} 

.buttons-excel, .buttons-pdf {
  float: left;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 1px 5px;
  font-size: 12px;  
  line-height: 1.5;
  background-color: #00958c;
  color: #ffffff;
}
.
.progress-bar-easy {
    background-color: #00958c;
}
